exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-preview-tsx": () => import("./../../../src/pages/api-preview.tsx" /* webpackChunkName: "component---src-pages-api-preview-tsx" */),
  "component---src-pages-catalogue-tsx": () => import("./../../../src/pages/catalogue.tsx" /* webpackChunkName: "component---src-pages-catalogue-tsx" */),
  "component---src-pages-journey-explorer-tsx": () => import("./../../../src/pages/journey-explorer.tsx" /* webpackChunkName: "component---src-pages-journey-explorer-tsx" */),
  "component---src-pages-mdx-preview-tsx": () => import("./../../../src/pages/mdx-preview.tsx" /* webpackChunkName: "component---src-pages-mdx-preview-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-templates-markdown-tsx": () => import("./../../../src/templates/markdown.tsx" /* webpackChunkName: "component---src-templates-markdown-tsx" */),
  "component---src-templates-reference-tsx": () => import("./../../../src/templates/reference.tsx" /* webpackChunkName: "component---src-templates-reference-tsx" */),
  "component---src-templates-workflow-tsx": () => import("./../../../src/templates/workflow.tsx" /* webpackChunkName: "component---src-templates-workflow-tsx" */)
}

